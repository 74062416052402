import logo from '../assets/images/logo.png';

const styles = {
  logo: {
    position: 'absolute',
    width: '100px',
    top: '25px',
    left: '25px',
  },
};

const Logo = () => {
  return (
    <img src={logo} style={styles.logo} alt='logo' />
  );
};

export default Logo;