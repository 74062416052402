import styled from 'styled-components';
const StyledAnchor = styled.a`
  margin: 0 3px;
  font-size: 1.5em;
  font-weight: 900;
  color: #333;
  text-decoration: none;
  padding: 0 10px;
  font-family: 'Bruno Ace SC', cursive;

  &:hover {
    color: #00f;
    text-decoration: underline;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }
`;
const styles = {
  navigation: {
    width: '100%',
    height: '50px',
    borderBottom: '1px solid #333',
    textAlign: 'center',
    backgroundColor: '#ccc',
    paddingTop: '5px',
  },
};
const Navigation = () => {
  return (
    <nav style={styles.navigation}>
      <StyledAnchor href="#about" title="About">About</StyledAnchor>
      <StyledAnchor href="#skills" title="Skills">Skills</StyledAnchor>
      <StyledAnchor href="#projects" title="Projects">Projects</StyledAnchor>
      <StyledAnchor href="#contact" title="Contact">Contact</StyledAnchor>
    </nav>
  );
};

export default Navigation;