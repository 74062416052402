import codepen from '../assets/images/codepen-64.png';
import dragonbit from '../assets/images/dragonbit-64.png';
import linkedIn from '../assets/images/linkedin-64.png';
import twitter from '../assets/images/twitter-64.png';

const styles = {
  socialIcon: {
    width: '48px',
    margin: '0 3px',
  },
}

const Social = ({pos}) => {
  return (
    <div style={pos}>
      <a href="https://www.michaellewis.ca" target="_blank" title="DragonBit Studio">
        <img src={dragonbit} style={styles.socialIcon} alt='dragonbit' />
      </a>
      <a href="https://www.michaellewis.ca" target="_blank" title="DragonBit Studio">
        <img src={codepen} style={styles.socialIcon} alt='codepen' />
      </a>
      <a href="https://www.michaellewis.ca" target="_blank" title="DragonBit Studio">
        <img src={linkedIn} style={styles.socialIcon} alt='linkedIn' />
      </a>
      <a href="https://twitter.com/Dakuma" target="_blank" title="Twitter">
        <img src={twitter} style={styles.socialIcon} alt='twitter' />
      </a>
    </div>
  );
};

export default Social;