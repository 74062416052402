
const styles = {
    container: {
      width: '100%',
      height: '900px',
      padding: '25px 50px',
      borderBottom: '1px solid #333',
      background: '#ccc',
    },
  };
  
  const Contact = () => {
    return (
      <section id="contact" style={styles.container}>
          <h1>CONTACT</h1>
      </section>
    );
  };
  
  export default Contact;