import heroVid from '../assets/videos/heroVideo.mp4';
import Logo from '../components/Logo';
import Social from './Social.Style';

const styles = {
  container: {
    height: '50vh',
    borderBottom: '1px solid #333',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    paddingTop: '250px',
  },
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#fff',
  },
  vid: {
    position: 'absolute',
    zIndex: '-1',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    transform: 'translate(-50%, -50%)',
  },
};
const heroStyle = {
  position: 'absolute',
  top: '25px',
  right: '25px',
};

const Hero = () => { 
  return (
    <div style={styles.container}>
      <Logo />
      <Social pos={heroStyle} />
      <h1 style={styles.title}>Neo is my Hero</h1>
      <video style={styles.vid} autoPlay muted loop>
        <source src={heroVid} type="video/mp4" />
      </video>
    </div>
  );
};

export default Hero;