import Skill from './Skill';
const styles = {
    container: {
      width: '100%',
      height: 'auto',
      padding: '50px',
      borderBottom: '1px solid #333',
      background: '#ccc',
      textAlign: 'center',
    },
    skillsHeading: {
      fontSize: '3.3em',
      textDecoration: 'underline',
      marginBottom: '25px',
    },
  };
  
  const Skills = () => {
    return (
      <section id="skills" style={styles.container}>
          <h1 style={styles.skillsHeading}>MY SKILLS</h1>
          <Skill years="7" source="devicon-html5-plain"/>
          <Skill years="6" source="devicon-css3-plain"/>
          <Skill years="7" source="devicon-javascript-plain"/>
          <Skill years="5" source="devicon-java-plain"/>
          <Skill years="8" source="devicon-csharp-plain"/>
          <Skill years="3" source="devicon-php-plain"/>
          <Skill years="2" source="devicon-microsoftsqlserver-plain"/>
          <Skill years="4" source="devicon-android-plain"/>
          <Skill years="5" source="devicon-apple-plain"/>
          <Skill years="4" source="devicon-ruby-plain"/>
          <Skill years="6" source="devicon-mysql-plain"/>
          <Skill years="3" source="devicon-react-original"/>
      </section>
    );
  };
  
  export default Skills;