import Social from './Social.Style';
const styles = {
    container: {
      position: 'relative',
      width: '100%',
      height: '150px',
      padding: '25px 50px',
      textAlign: 'center',
      borderBottom: '1px solid #333',
      marginBottom: '50px',
      background: '#333',
      fontSize: '.67em',
      color: '#fff',
      paddingTop: '25px',
    },
    green: {
        color: '#0f0',
    },
  };
  const footerStyle = { 
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  };
  
  const Footer = () => {
    return (
      <footer style={styles.container}>
        <Social pos={footerStyle} />
        <h1>{'\u00A9'} tri<span style={styles.green}>OS</span> Ninjas in training.</h1>
      </footer>
    );
  };
  
  export default Footer;