
const styles = {
    container: {
      width: '100%',
      height: '900px',
      padding: '25px 50px',
      borderBottom: '1px solid #333',
      background: '#fff',
    },
  };
  
  const Projects = () => {
    return (
      <section id="projects" style={styles.container}>
          <h1>PROJECTS</h1>
      </section>
    );
  };
  
  export default Projects;