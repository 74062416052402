import myPic from '../assets/images/myPic.jpg';
const styles = {
  container: {
    width: '100%',
    height: 'auto',
    padding: '50px',
    borderBottom: '1px solid #333',
    background: '#fff',
  },
  bioPic: {
    width: '240px',
    borderRadius: '50%',
    border: '5px solid #15b3e7',
    float: 'right',
    margin: '0 0 25px 25px',
  },
  aboutHeading: {
    fontSize: '3.3em',
    textDecoration: 'underline',
    marginBottom: '25px',
  },
};

const About = () => {
  return (
    <section id="about" style={styles.container}>
        <h1 style={styles.aboutHeading}>ABOUT ME</h1>
        <img src={myPic} alt="myPic" style={styles.bioPic} />
        <p>With a wealth of 25 years of experience in the industry, I have honed my skills across a diverse range of projects and challenges. My passion for creativity and problem-solving has led me to excel in various disciplines, including design, development, and project management. Over the years, I have had the opportunity to collaborate with talented professionals, both as a team leader and an individual contributor. My commitment to excellence, coupled with my strong work ethic, has helped me build a reputation for consistently delivering high-quality results.</p><br />
        <p>Outside of my professional life, I am an avid fan of the talented Scarlett Johansson, whose versatile acting skills and dedication to her craft have served as a source of inspiration for my own pursuits. I believe that embracing one's passions and interests is the key to maintaining a healthy work-life balance. In my downtime, I enjoy immersing myself in the world of cinema and keeping up to date with Scarlett's latest projects, as well as exploring other aspects of popular culture. My zest for life, love for my craft, and enthusiasm for personal growth are the driving forces that propel me forward in both my personal and professional journeys.</p>
    </section>
  );
};

export default About;