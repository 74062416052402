
const styles = {
    container: {
      width: '20%',
      maxWidth: '260px',
      minWidth: '160px',
      height: '200px',
      padding: '20px 10px',
      border: '1px solid #333',
      margin: '10px 1%',
      background: '#fff',
      borderRadius: '16px',
      boxShadow: '2px 2px 5px #777',
      color: '#333',
      display: 'inline-block',
    },
    icon: {
        fontSize: '7rem',
        color: '#444',
    },
    heading: {
        background: '#eee',
        marginTop: '10px',
        padding: '5px 15px',
        borderRadius: '16px',
        fontSize: '.9em',
        width: '100%',
    },
  };
  
  const Skill = ({source, years}) => {
    return (
      <div style={styles.container}>
        <i style={styles.icon} class={source}></i>
        <h3 style={styles.heading}>{years} years XP</h3>
      </div>
    );
  };
  
  export default Skill;